<template>
  <div class="wrapper">
    <div class="clearfix electric-select">
      <el-form :inline="true">
        <el-form-item label="标题">
          <el-input v-model="title" clearable></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input v-model="content" clearable></el-input>
        </el-form-item>
        <el-form-item label="会议时间">
          <el-date-picker
            clearable
            v-model="start_time"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyyMMdd">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search()">
            <i class="el-icon-search"></i>
            查询
          </el-button>
          <el-button type="primary" @click="add()">
            <i class="el-icon-plus"></i>
            新增
          </el-button>
          <el-button v-if="tableData.length" @click="exportList" type="primary" size="mini">导出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      :data="tableData"
      border
      id="table-out"
      tooltip-effect="dark myTooltips"
      style="width: 100%">
      <el-table-column
        prop="title"
        label="标题">
      </el-table-column>
      <el-table-column
        prop="content"
        label="内容"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="start_time"
        label="会议时间">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间">
      </el-table-column>
      <el-table-column width="320px" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="lookDetail(scope.row)">查看</el-button>
          <el-button type="primary" size="small" @click="upDateFn(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="deleteFn(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum"></el-pagination>
    </div>


    <el-dialog :title="operateTitle" :visible.sync="dialogFormVisible" width="680px" :show-close="false" :close-on-click-modal="false">
      <el-form :model="form" :rules="rules" ref="form" :disabled="operateFlag == 'detail'?true:false">
        <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="内容" :label-width="formLabelWidth" prop="content">
          <el-input v-model="form.content" type="textarea" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="会议时间" :label-width="formLabelWidth" prop="start_time">
          <el-date-picker
            v-model="form.start_time"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="选择会议时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="工作照片" :label-width="formLabelWidth">
          <el-upload ref="upload" action=""  accept="image/png, image/jpeg, image/.jpg"
						list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
						:on-change="handleChange" :file-list="fileList" :auto-upload="false">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog title="图片预览" append-to-body :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
        </el-form-item>
        <el-form-item label="会议附件" :label-width="formLabelWidth">
          <el-upload
            ref="uploadFj"
            action=""
            :on-change="handleChangeFj"
            :on-remove="handleRemoveFj"
            :on-preview="operateFlag =='detail'?downLoadFJ:function(){}"
            :file-list="fileListFj"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" >
        <el-button v-if="operateFlag !='detail'"  size="small" type="primary" @click="onSubmit('form')">提 交</el-button>
        <el-button v-if="operateFlag !='detail'"  size="small" @click="resetForm('form')">取 消</el-button>

        <el-button v-else type="primary" size="small"  @click="resetForm('form')">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import XLSX from "xlsx";
import {
  meetPage,
  meetDel,
  meetUpdate,
  meetAdd,
  meetingExport
	} from '@/api/ninesite'
export default {
  name:"meeting",
  components:{},
  props:{},
  data(){
    return {
      tableData: [],
      title: '',		
      content: '',		
      start_time: '',
      currentPage: 1,
      fenye: {
        pagesizes: [10, 20, 50],
        pagesize: 10,
        totalnum: 0
      },
      dialogFormVisible: false,
      form: {
        title: '',
        content: '',
        start_time: '',
        pic: '',
        filelist: ''
      },
      formLabelWidth: '90px',
      fileList: [],
      fileListFj: [],
      dialogImageUrl: '',
      dialogVisible: false,
      rules:{
        title: [
          { required: true, message: '请输入标题', trigger: 'change' },
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'change' }
        ],
        start_time: [
          { required: true, message: '请选择会议时间', trigger: 'change' }
        ]
      },
      operateFlag: false,
      operateTitle: ''
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.search();
  },
  computed:{},
  methods:{
    search(){
      this.currentPage = 1;
      this.getList();
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.fenye.pagesize = val;
      this.search;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //下载附件
    downLoadFJ(val){
      FileSaver.saveAs(val.path, val.path.split('/downfile/')[1]);
    },
    //新增
    add(){
      this.dialogFormVisible = true;
      this.operateFlag = 'add';
      this.operateTitle = '新增';
    },
    //详情
    lookDetail(row){
      this.upDateFn(row);
      this.operateFlag = 'detail';
      this.operateTitle = '查看详情';

    },
    //文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      this.$message.warning('只能选择3个文件!');
    },
    //修改
    upDateFn(row){
      this.operateFlag = 'update';
      this.operateTitle = '修改';
      this.form = {...row};
      this.dialogFormVisible = true;
      
      //照片返填
      this.fileList = [];
      let pic = row.pic?row.pic.split(','):[];
      if(pic.length){
        pic.map(picId=>{
          this.fileList.push({
            upId: picId,
            name: picId
          })
        })
        row.picurl.map((picUrl,index)=>{
          this.fileList[index].url = picUrl
        })
      }
      
      //附件返填
      let fileurls = [...row.filehurl];
      fileurls.map(item=>{
        item.name = item.filename
      })
      this.fileListFj = fileurls;
      
    },
    //删除
    deleteFn(row){
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        meetDel({
          userid: this.GLOBAL.adminId(),
          meetid: row.id,
        }).then(res => {
          if(res.result == 200){
            this.$message.success('操作成功');
            this.search()
          }else{
            this.$message.success(res.description);
          }
        })
      }).catch(() => {

      });
    },
    //导出列表
    exportList(){
      this.exportLoading = true;
      meetingExport({
        userid: this.GLOBAL.adminId(),
        title: this.title,
        content: this.content,
        start_time: this.start_time
      }).then((res) => {
        this.exportLoading = false;
        if (res.result == '200') {
          console.log(res);
            FileSaver.saveAs(res.detail, res.detail.split('/downfile/')[1]);
        } else {
          this.$message.error(res.description);
        }
      })
    },
    getList(){
      meetPage({
        userid: this.GLOBAL.adminId(),
        title: this.title,
        content: this.content,
        start_time: this.start_time,
        page: this.currentPage,
				pageSize: this.fenye.pagesize,
      }).then(res => {
        let list = res.detail.list;
        this.tableData = list;
        this.fenye.totalnum = res.detail.totalRow;
      })
    },
    //列表图 文件状态改变时的钩子
    handleChange(file, fileList) {
      /* const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
        let index = fileList.indexOf(file)
        if (index !== -1) {
          fileList.splice(index, 1)
        }
      } */
      

      let formData = new FormData();
      formData.append('upfilelist', file.raw);
      this.$axios.post('/fileutil/UpLoadFileList', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data.result == 200) {
          console.log(res.data.detail)
          file.id = res.data.detail;
          console.log(fileList)
          let ids = [];
          fileList.map(f => {
            ids.push(f.id)
          })
          this.form.pic = ids.length ? ids.join(',') : '';
        } else {
          this.$message.error(res.data.description);
        }
      })

    },
    //列表图 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      let ids = [];
      fileList.map(f => {
        ids.push(f.id)
      })
      this.form.pic = ids.length ? ids.join(',') : '';
    },
    //图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //列表图 文件状态改变时的钩子
    handleChangeFj(file, fileList) {
      /* const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!');
        let index = fileList.indexOf(file)
        if (index !== -1) {
          fileList.splice(index, 1)
        }
      } */

      let formData = new FormData();
      formData.append('upfilelist', file.raw);
      this.$axios.post('/fileutil/UpLoadFileList', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data.result == 200) {
          console.log(res.data.detail)
          file.id = res.data.detail;
          console.log(fileList)
          let ids = [];
          fileList.map(f => {
            ids.push(f.id)
          })
          this.form.filelist = ids.length ? ids.join(',') : '';
        } else {
          this.$message.error(res.data.description);
        }
      })

    },
    //列表图 文件列表移除文件时的钩子
    handleRemoveFj(file, fileList) {
      let ids = [];
      fileList.map(f => {
        ids.push(f.id)
      })
      this.form.filelist = ids.length ? ids.join(',') : '';
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.operateFlag == 'add'){
            meetAdd({
              userid: this.GLOBAL.adminId(),
              title: this.form.title,
              content: this.form.content,
              start_time: this.form.start_time,
              pic: this.form.pic,
              filelist: this.form.filelist
            }).then(res => {
              this.$message.success('操作成功');
              this.resetForm('form')
              this.search();
            })
          }else{
            meetUpdate({
              userid: this.GLOBAL.adminId(),
              title: this.form.title,
              content: this.form.content,
              start_time: this.form.start_time,
              pic: this.form.pic,
              filelist: this.form.filelist,
              meetid: this.form.id
            }).then(res => {
              this.$message.success('操作成功');
              this.resetForm('form')
              this.search();
            })
          }
          
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$refs.upload.clearFiles();
      this.$refs.uploadFj.clearFiles();
			this.dialogFormVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .block{
    margin: 20px 0;
  }
  
</style>

<style>
  .myTooltips{ 
    width: 30%
  }
</style>